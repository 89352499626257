import React, {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import './App.css';
import './index.css';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Cookies from 'js-cookie';
import Layout from "./Screens/Layout";


const FormationsRealisees = React.lazy(() => import("./Screens/Documents"));

const App = () => {
  const [mode, setMode] = React.useState();
  const [notification, updateNotification] = useState(false);

  useEffect(() => {
    if (Cookies.get('theme')) {
      setMode(Cookies.get('theme'));
    } else {
      setMode('dark'); // thème par défaut
    }
    if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    } else {
      document.body.classList.remove('light-theme');
    }
    if (Cookies.get('theme') === 'dark') {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.add('light-theme');
    }
  }, []);

  const theme = React.useMemo(
      () =>
          createTheme({
            components: {
              MuiButton: {
                styleOverrides: {
                  root: {
                    color: 'inherit', // Hérite de la couleur du parent
                    backgroundColor: 'transparent', // Fond transparent
                    boxShadow: 'none', // Pas d'ombre
                    '&:hover': {
                      backgroundColor: 'transparent', // Fond transparent au survol
                      boxShadow: 'none', // Pas d'ombre au survol
                    },
                    '&:active': {
                      boxShadow: 'none', // Pas d'ombre en état actif
                    },
                    '&:focus': {
                      boxShadow: 'none', // Pas d'ombre en état focus
                    },
                  },
                },
              },
            },
            palette: {
              mode,
            },
            accordion: {
              backgroundImage: 'none',
            },
            accordionSummary: {
              backgroundImage: 'none',
            },
            typography: {
              fontFamily: 'var(--font-family-app)', // Définit la font par défaut pour tous les composants MATERIAL UI
            },

          }),
      [mode],
  );
  //theme

  React.useEffect(() => {
    // 👇 add class to body element
    if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    } else {
      document.body.classList.remove('light-theme');
    }
    if (theme.palette.mode === 'dark') {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.add('light-theme');
    }
  }, [theme]);

  return (
      <ThemeProvider theme={theme}>
        <Layout notification={notification}>
          <Routes>
            <Route path="/">
              <Route index
                     element={
                       <React.Suspense fallback={<>...</>}>
                         <FormationsRealisees/>
                       </React.Suspense>
                     }/>
            </Route>
          </Routes>
        </Layout>
      </ThemeProvider>
  );
}

export default App;
