/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes formations';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mes formations réalisées';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-docs-veto.svg";
/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-mesformationsrealisees-dev.cristal-hub.fr/customer-documents";
export const zUrlBack = "https://api-mesformationsrealisees-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";

export const Typedoc =[
    { id: 8, title: 'Attestations de formation' },
    { id: 9, title: 'Conventions de formation' },
    { id: 10, title: 'Programmes de formation' },
];
export const Typedocid = [8,9,10];

/**
 * Fin constantes spécifiques alvéole
 */
/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";

